import "./navbar.css";

let siteNavbar;

function toggleMenu() {
  if (siteNavbar.classList.contains("open")) {
    siteNavbar.classList.remove("open");} 
  else {
    siteNavbar.classList.add("open"); }
}

document.addEventListener("turbolinks:load", event => {
  siteNavbar = document.querySelector(".site-navbar");
  document.querySelector(".site-navbar--caller").addEventListener("click", toggleMenu, false);
});
