import "./page.css";

document.addEventListener("turbolinks:load", function(event) {
  // setTimeout(function() {
  //   document.querySelector(".page-transition").classList.remove("welcome"); }, 900);

  // const tpts = document.querySelectorAll(".tpt");
  // for (let tpt of tpts) {
  //   tpt.addEventListener("click", function(event) {
  // 		event.preventDefault();

  //     document.querySelector(".page-transition").classList.add("bye");

  //     const href = event.currentTarget.getAttribute("href");

  //     setTimeout(function() {
  // 			window.location.href = href; }, 700);
  // 	});
  // }
});