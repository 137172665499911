let PAGE_ID;

/*
  Smooth scrolling to an anchor.

  params:
    object: e           => event
    object: [respond]   => <a> object (link)
*/
function scrollAnchors(e, respond = null) {
	const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

  e.preventDefault();

  const targetID = (respond) ? respond.getAttribute('href') : this.getAttribute('href');
  const targetAnchor = document.querySelector(targetID);
  if (!targetAnchor) return;
  const originalTop = distanceToTop(targetAnchor);

  window.scrollBy({top: originalTop, left: 0, behavior: 'smooth'});

  const checkIfDone = setInterval(function() {
		const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
		if (distanceToTop(targetAnchor) === 0 || atBottom) {
			targetAnchor.tabIndex = '-1';
			targetAnchor.focus();
			window.history.pushState('', '', targetID);
			clearInterval(checkIfDone);
		}
	}, 100);
}

// Shared functions <>

window.getPageId = function() {
  return PAGE_ID; };

window.generateId = function() {
  return new Date().getTime(); };

window.sleep = function(milliseconds) {
  const start = new Date().getTime();
  for (let i = 0; i < 1e7; i += 1) {
    if (new Date().getTime() - start > milliseconds) {
			break; }
	}
};

window.getSiblings = function(elem) {
  const siblings = [];
  let sibling = elem.parentNode.firstChild;

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
			siblings.push(sibling); }

    sibling = sibling.nextSibling;
  }

  return siblings;
};

window.isInViewport = function(elem) {
  const width = elem.offsetWidth;
  const height = elem.offsetHeight;
  let top = elem.offsetTop;
  let left = elem.offsetLeft;

  while (elem.offsetParent) {
    elem = elem.offsetParent;
    top += elem.offsetTop;
    left += elem.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
};

window.throttle = function(func, wait, options) {
  let context, args, result;
  let timeout = null;
  let previous = 0;

  if (!options) {
		options = {}; }

  let later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);

    if (!timeout) {
			context = args = null; }
  };

  return function() {
    let now = Date.now();
    if (!previous && options.leading === false) previous = now;
    let remaining = wait - (now - previous);
    context = this;
    args = arguments;

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      previous = now;
      result = func.apply(context, args);

      if (!timeout) {
				context = args = null; }

    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining); }

    return result;
  };
};

window.createCookie = function(name, value, days) {
  let expires = "";

  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }

  document.cookie = `${name}=${value}${expires}; path=/`;
};

window.readCookie = function(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];

    while (c.charAt(0) == " ") {
			c = c.substring(1, c.length); }

    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length); }
  }

  return undefined;
};

window.eraseCookie = function(name) {
  createCookie(name, "", -1); };

window.responsiveBgs = function() {
  const responsiveBgs = document.querySelectorAll(".responsive-bg");

  if (responsiveBgs != undefined) {
    let vertical = true;

    if (window.screen.orientation != undefined) {
      vertical = ["portrait-primary", "portrait-secondary"].includes(window.screen.orientation.type); }

    const mobileScreen = Math.max(document.documentElement.clientWidth, window.clientWidth || 0) <= 1024 && vertical;

    for (const responsiveBg of responsiveBgs) {
      if (responsiveBg.style.backgroundImage != undefined) {
        let image;

				if (mobileScreen) {
					image = responsiveBg.getAttribute("data-image-vertical"); }

				if (image == undefined) {
					image = responsiveBg.getAttribute("data-image"); }

				responsiveBg.style.backgroundImage = `url(${image})`;
      }
    }
  }
};

// Shared functions </>

// Listeners <>

// function globalResizeListener(event) {
// }
// document.addEventListener("resize", throttle(globalResizeListener, 20));

// Listeners </>

document.addEventListener("turbolinks:load", function(event) {
  PAGE_ID = document.querySelector(".page").id;

   // bug fix: turbolinks y anchors
   document.addEventListener('turbolinks:click', function(event) {
    if (event.target.getAttribute('href').charAt(0) === '#') {
      return event.preventDefault();
    }
  });

  // bug fix: botón atrás en Firefox
  window.addEventListener("unload", event => {
    window.removeEventListener("unload", this);
  });

  // scroll suave
  const links = document.getElementsByTagName("a");

	for (let i = 0; i < links.length; i++) {
		const link = links[i];

    if (
          (link.href && link.href.indexOf("#") != -1)
          &&
          (link.pathname == location.pathname || ('/' + link.pathname == location.pathname && link.search == location.search))
        )
    {
      link.onclick = scrollAnchors;
    }
  }

  // devuelve el scroll al inicio al cambiar de o recargar la página
  Turbolinks.BrowserAdapter.prototype.reload = function () {
    window.scrollTo(0, 0);
    window.location.reload();
  }

  // window.dispatchEvent(new Event("resize"));
	// globalResizeListener();
});
