require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

const images = require.context("../images", true)
const imagePath = (name) => images(name, true)

import "../assets/icons/icons.css";
import "../stylesheets/normalize.css";
import "../stylesheets/application.css";

// Javascripts <>
import "../javascripts/app";
import Rellax from "../javascripts/rellax.js";
import {
  jarallax,
  jarallaxElement
} from "jarallax";
// Javascripts </>

// Components <>
import "../components/page/page";
import "../components/button/button";
import "../components/navbar/navbar";
import "../components/reserve-now/reserve-now";
import "../components/cafeteria/cafeteria";
import "../components/footer/footer";
import "../components/cookies-modal/cookies-modal";
// Components </>

// Pages <>
import "../stylesheets/pages-index.css";
import "../stylesheets/pages-vr.css";
import "../stylesheets/pages-jumping.css";
import "../stylesheets/pages-jumping.css";
import "../stylesheets/pages-contact.css";
import "../stylesheets/pages-legal.css";
import "../stylesheets/pages-prices.css";
import "../stylesheets/pages-birthdays.css";
import "../stylesheets/pages-courses.css";
import "../stylesheets/reservations-new.css";
import "../stylesheets/reservations-show.css";
import "../stylesheets/posts-index.css";
import "../stylesheets/posts-show.css";
import "../stylesheets/game-azura.css";
import "../stylesheets/error-404.css";
// Pages </>

let lastClickedRow;

function initReservationForm() {
  if ($(document).width() <= 700) {
    resume_on_mobile_position
    $('#resume').appendTo('#resume_on_mobile_position');
  }

  var form = $('#new_reservation');

  $(".simple-calendar").on('click', 'td > a', function(e) {
    e.preventDefault();
    $('#game_slots').load($(this).attr('href'));
    $('.selected-day').removeClass("selected-day");
    $(this).addClass("selected-day");
  });

  $('#game_slots').on('click', 'tr', function(e) {
    $(this).siblings().find('.slot.selected').removeClass("selected");
  });

  const reservationsPlayersField = document.getElementById("reservation_players");
  $("#game_slots").on("click", "span.slot", function(e) {
    if ($(this).hasClass("used")) {
      return; }

    if ($(this).closest("tr").attr('id') != lastClickedRow) {
      document.getElementById("reservation_players").value = 0;
      lastClickedRow = $(this).closest("tr").attr('id');
    }

    $(this).toggleClass("selected");


    if ($(this).hasClass("selected")) {
      document.getElementById("reservation_game_slot_id").value = $(this).closest("tr").data("slot");
      reservationsPlayersField.value++;
    } else {
      reservationsPlayersField.value--;
    }

    if (reservationsPlayersField.value == 0) {
      document.getElementById("resume_name").innerText = "Elige un día y un juego";
      document.getElementById("resume_date").innerText = "";
    } else  {
      document.getElementById("resume_name").innerText = $(this).closest("tr").find(".name").text();
      document.getElementById("resume_date").innerText = `el día ${$(".simple-calendar .selected-day").text()} a las ${$(this).closest("tr").find(".time").text()}`;
    }

    document.getElementById("resume_price").innerText = `${
      parseFloat((Math.round(reservationsPlayersField.value *
      $(this).parent().closest("tr").data("price") + "e+2") + "e-2")).toFixed(2)
    } €`;

    let playersLabel = reservationsPlayersField.value > 1 ? "jugadores" : "jugador";
    if (reservationsPlayersField.value == 1) {
      playersLabel += ' <span style="color: #7118d9;" class="icon-info">revisa puntos 4 y 5</span>'
    }
    document.getElementById("resume_players").innerHTML = `${reservationsPlayersField.value} ${playersLabel}`;
  });
}

document.addEventListener("turbolinks:load", function() {
  window.scrollTo(0, 0);

  if (getPageId() === "pages_jumping") {
    new Rellax(".rellax .rellax--image");

    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.4 });
  }

  if (getPageId() === "reservations_new") {
    initReservationForm(); }

  if (getPageId() === "pages_game_azura") {
    new Rellax(".rellax.title", {
      speed: 2 });
  }

}, false);
