import "./cookies-modal.css";

document.addEventListener("turbolinks:load", event => {   
  if (readCookie("cookies_thejumper_accepted") === undefined) {
    const cookiesModal = document.querySelector("#cookies_modal");

    if (cookiesModal != undefined) {
      cookiesModal.classList.add("present");

      cookiesModal.querySelector(".close").addEventListener("click", event => {
        createCookie("cookies_thejumper_accepted", "displayed", 365);
        cookiesModal.classList.add("hide");
      }, false);
    } else {
      cookiesModal.remove(); }
  }  
}, false);